export default {
  'app.setting.pagestyle': '整体风格设置',
  'app.setting.pagestyle.light': '亮色菜单风格',
  'app.setting.pagestyle.dark': '暗色菜单风格',
  'app.setting.pagestyle.realdark': '暗黑模式',
  'app.setting.themecolor': '主题色',
  'app.setting.navigationmode': '导航模式',
  'app.setting.content-width': '内容区域宽度',
  'app.setting.fixedheader': '固定 Header',
  'app.setting.fixedsidebar': '固定侧边栏',
  'app.setting.multitab': '显示页签',
  'app.setting.sidemenu': '侧边菜单布局',
  'app.setting.topmenu': '顶部菜单布局',
  'app.setting.content-width.fixed': 'Fixed',
  'app.setting.content-width.fluid': 'Fluid',
  'app.setting.othersettings': '其他设置',
  'app.setting.weakmode': '色弱模式',
  'app.setting.copy': '拷贝设置',
  'app.setting.loading': '加载主题中',
  'app.setting.copyinfo': '拷贝设置成功 src/config/defaultSettings.js',
  'app.setting.production.hint': '配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件',
  'app.setting.themecolor.daybreak': '拂晓蓝',
  'app.setting.themecolor.dust': '薄暮',
  'app.setting.themecolor.volcano': '火山',
  'app.setting.themecolor.sunset': '日暮',
  'app.setting.themecolor.cyan': '明青',
  'app.setting.themecolor.green': '极光绿',
  'app.setting.themecolor.geekblue': '极客蓝',
  'app.setting.themecolor.purple': '酱紫',

  '系统用户': {
    '开始时间': '开始时间',
    '结束时间': '结束时间',
  },

  '通用': {
    '按钮': {
      '新增': '新增',
      '确定': '确定',
      '审核': '审核',
      '修改': '修改',
      '复制': '复制',
      '删除': '删除',
      '关闭': '关闭',
      '导出': '导出',
      '选中导出': '选中导出',
      '导入': '导入',
      '查询': '查询',
      '重置': '重置',
      '详情': '详情',
      '上传': '上传',
      '展开': '展开',
      '收起': '收起',
      '更多': '更多',
      '保存': '保存',
      '取消': '取消',
      '翻译': '翻译',
      '刷新缓存': '刷新缓存',
      '详情及审核': '详情及审核',
      '客服': '客服',
      '平台客服': '平台客服',
    },
    '输入':{
      '请输入':'请输入 ',
      '请选择':'请选择 ',
    },
    '文本': {
      '是': '是',
      '否': '否',
      '启用': '启用',
      '禁用': '禁用',
      '操作': '操作',
      '添加': '添加',
      '创建时间': '创建时间',
      '排序': '排序',
      '修改': '修改',
      '详情': '详情',
      '确认删除所选中数据': '确认删除所选中数据?',
      '删除成功': '删除成功',
      '确认取消': '确认取消?',
      '取消成功': '取消成功',
      '新增成功': '新增成功',
      '修改成功': '修改成功',
      '上传成功': '上传成功',
      '共': '共',
      '条': '条',
      '默认': '默认',
      '中等': '中等',
      '紧凑': '紧凑',
      '列展示排序': '列展示排序',
      '列排序需拖动名称': '列排序需拖动名称',
      '不能为空': '不能为空',
      '不能小于0': '不能小于0',
      '多语言配置': '多语言配置',
      '语言': '语言',
      '根节点': '根节点',
      '创建开始时间': '创建开始时间',
      '创建结束时间': '创建结束时间',
      '选择商品': '选择商品',
      '详情及审核': '详情及审核',
      '确认核销': '确认核销?',
      '核销成功': '核销成功',
      '是否确认导出': '是否确认导出?',
      '此操作将导出当前条件下所有数据而非选中数据': '此操作将导出当前条件下所有数据而非选中数据',
      '此操作将导出选中数据': '此操作将导出选中数据',
    },
  },
  '广告': {
    '位置': '位置',
    '跳转类型': '跳转类型',
    '广告名称': '广告名称',
    '开始时间': '开始时间',
    '结束时间': '结束时间',
    '选择申请': '选择申请',
    '外链': '外链',
    '内容详情': '内容详情',
    '是否隐藏': '是否隐藏',
    '展示时长': '展示时长',
    '展示次数': '展示次数',
    '点击次数': '点击次数',
    '跳转次数': '跳转次数',
    '封面图': '封面图',
    '详情图': '详情图',
  },

  '商品分类': {
    '商品分类': '商品分类',
    '分类名称': '分类名称',
    '分类图片': '分类图片',
    '上级分类': '上级分类',
    '分类等级': '分类等级',
    '是否显示': '是否显示',
    '是否首页展示': '是否首页展示',
    '是否参与筛选': '是否参与筛选',
    '创建时间': '创建时间',

  },
  '商品标签':{
    '标签名称':'标签名称',
    '图标':'图标',
    '颜色':'颜色',
    '状态':'状态',
    '创建时间':'创建时间',
    '更新时间':'更新时间',
    '备注':'备注',
  },
  '商品': {
    '套餐': '套餐',
    '标签': '标签',
    '备注': '备注',
    '售卖区域': '售卖区域',
    '批量上架': '批量上架',
    '批量下架': '批量下架',
    '批量复制': '批量复制',
    '确认复制': '确认复制?',
    '复制成功': '复制成功',
    '选择明星': '选择明星',
    '选择运费模板': '选择运费模板',
    '基础信息': '基础信息',
    '标签信息': '标签信息',
    '规格信息': '规格信息',
    '详情信息': '详情信息',
    '上架成功': '上架成功',
    '下架成功': '下架成功',
    '确认上架': '确认上架?',
    '确认下架': '确认下架?',
    '添加规格': '添加规格',
    '修改规格': '修改规格',
    '商品类型': '商品类型',
    '商品': '商品',
    '明星': '明星',
    '限购数': '限购数',
    '售卖开始时间': '售卖开始时间',
    '售卖结束时间': '售卖结束时间',
    '是否多规格': '是否多规格',
    '是否允许合并购买': '是否允许合并购买',
    '商品颜色': '商品颜色',
    '商品详情': '商品详情',
    '市场价': '市场价(划线价格)',
    '成本价': '成本价',
    '虚拟销量': '虚拟销量',
    '是否展示销量': '是否展示销量',
    '商品价': '商品价',
    '商品编号': '商品编号',
    'SKU编号': 'SKU编号',
    'SKU名字': 'SKU名字',
    '规格名称': '规格名称',
    '商品名称': '商品名称',
    '商品重量': '商品重量',
    '包装单位': '包装单位',
    '商品主图': '商品主图',
    '商品视频': '商品视频',
    '商品轮播图': '商品轮播图',
    '商品分类': '商品分类',
    '商品关键词': '商品关键词',
    '品牌': '品牌',
    '运费模板': '运费模板',
    '商品价格': '商品价格',
    '活动价格': '活动价格',
    '秒杀价格': '秒杀价格',
    '团购价格': '团购价格',
    '会员价格': '会员价格',
    '限购数量': '限购数量',
    '商品库存': '商品库存',
    '预警库存': '预警库存',
    '商品状态': '商品状态',
    '实际销量': '实际销量',
    '售卖范围': '售卖范围',
    '是否推荐': '是否推荐',
    '是否新品': '是否新品',
    '是否样品': '是否样品',
    '是否日历展示': '是否日历展示',
    '是否精品': '是否精品',
    '是否免费领': '是否免费领',
    '是否限购': '是否限购',
    '是否爆款': '是否爆款',
    '售后方式': '售后方式',
    '创建时间': '创建时间',
    '规格': {
      '添加规格值': '添加规格值',
      '规格名称': '规格名称',
      '规格值': '规格值',
      '是否可见': '是否可见',
      '规格图片': '规格图片',
      '是否有规格图片': '是否有规格图片',
    },
    'sku': {
      '名称': '名称',
      '编码': '编号',
      '价格': '价格',
      '活动价格': '活动价格',
      '库存': '库存',
      '重量': '重量',
      '图片': '图片',
    },
  },
  '品牌': {
    '品牌名称': '品牌名称',
    '品牌备注': '品牌备注',
    '品牌首字母': '品牌首字母',
    'logo': 'logo',
    '创建时间': '创建时间',
  },
  '明星分类': {
    '分类名称': '分类名称',
    '是否显示': '是否显示',
    '创建时间': '创建时间',
    '备注': '备注',
  },
  '明星': {
    '明星名字': '明星名字',
    '明星分类': '明星分类',
    '明星照片': '明星照片',
    '背景图': '背景图',
    '网址': '网址',
    '状态': '状态',
    '基础粉丝数': '基础粉丝数',
    '关注数': '关注数',
    '明星介绍': '明星介绍',
    '创建时间': '创建时间',
    '备注': '备注',
  },
  '明星俱乐部': {
    '俱乐部名字': '俱乐部名字',
    '商品数': '商品数',
    '创建时间': '创建时间',
    '备注': '备注',
  },

  '活动分类':{
    '分类名称':'分类名称',
    '状态':'状态',
    '排序':'排序',
    '创建者':'创建者',
    '创建时间':'创建时间',
    '修改人':'修改人',
    '更新时间':'更新时间',
    '备注':'备注',
    '首页图片':'首页图片',
  },
  '活动记录': {
    '活动编号':'活动编号',
    '商品最大购买数量':'商品最大购买数量',
    '排行':'排行',
    '选择活动':'选择活动',
    '是否上架':'是否上架',
    '活动类型':'活动类型',
    '活动名称':'活动名称',
    '开始时间':'开始时间',
    '结束时间':'结束时间',
    '活动封面图':'活动封面图',
    '分享海报图':'分享海报图',
    '分享文案':'分享文案',
    '限制会员等级':'限制会员等级',
    '最多参与次数':'最多参与次数',
    '活动详情':'活动详情',
    '状态':'状态',
    '是否展示到日历':'是否展示到日历',

    '创建时间':'创建时间',
    '活动参与区域':'活动参与区域'
  },
  '运费模板': {
    '计费方式':'计费方式'
  },
  '收货地址': {
    '应援地址':'应援地址',
    '收货区域':'收货区域',
    '收货人手机号':'收货人手机号',

  },
  '广告投放审核': {
    '联系电话': '联系电话',
    '广告名称': '广告名称',
    '广告简介': '广告简介',
    '广告活动开始时间': '开始时间',
    '广告活动结束时间': '结束时间',
    '审核状态': '审核状态',
    '审核时间': '审核时间',
    '姓名': '姓名',
    '创建时间':'创建时间',
    '原因': '原因',
    '是否通过': '是否通过',

  },
  '订单取消原因': {
    '名称':'名称',
    '类型':'类型',
    '创建时间':'创建时间',
    '是否显示':'是否显示',
  },

  '等级设置': {
    '会员等级名称': '等级名称',
    '等级图片': '等级图片',
    '等级图标': '等级图标',
    '会员等级排序': '会员等级排序',
    '升级所需积分': '升级所需积分',
  },
  '商品足迹': {
    '商品名称': '商品名称',
    '商品主图': '商品主图',
    '排序': '排序',
    '搜索次数': '搜索次数',
    '创建时间': '创建时间',
  },
  '订单发货记录': {
    '发货方式': '发货方式',
    '发货人手机号': '发货人手机号',
    '发货时间': '发货时间',
    '发货记录编号': '发货记录编号',
    '发货开始时间': '发货开始时间',
    '发货结束时间': '发货结束时间',

  },

  '销售订单': {
    '全部订单': '全部订单',
    '此操作将导出当所有待发货订单而非选中数据':'此操作将导出当所有待发货订单而非选中数据',
    '导出待发货':'导出待发货',
    '导入发货单':'导入发货单',
    '接单':'接单',
    '接单成功':'接单成功',
    '物流公司':'物流公司',
    '物流单号':'物流单号',
    '物流详情':'物流详情',
    '寄件人手机号':'寄件人手机号',
    '订单编号':'订单编号',
    '订单类型':'订单类型',
    '订单来源':'订单来源',
    '订单数量':'订单数量',
    '订单信息':'订单信息',
    '订单详情':'订单详情',
    '三方订单号':'三方订单号',
    '买家名称':'买家名称',
    '买家信息':'买家信息',
    '手机号或邮箱':'手机号或邮箱',
    '订单状态':'订单状态',
    '下单时间':'下单时间',
    '下单开始时间':'下单开始时间',
    '下单结束时间':'下单结束时间',
    '支付方式':'支付方式',
    '支付时间':'支付时间',
    '退款状态':'退款状态',
    '商品数量':'商品数量',
    '商品金额':'商品金额',
    '商品实付金额':'商品实付金额',
    '优惠金额':'优惠金额',
    '平台抽成':'平台抽成',
    '订单金额':'订单金额',
    '运费金额':'运费金额',
    '运费实付':'运费实付',
    '用户备注':'用户备注',
    '商品信息':'商品信息',
    '活动信息':'活动信息',
    '收货地址信息':'收货地址信息',
    '实付单价':'实付单价',
    '数量':'数量',
    '收货人姓名':'收货人姓名',
    '性别':'性别',
    '手机号':'手机号',
    '邮箱':'邮箱',
    '地址类型':'地址类型',
    '地区':'地区',
    '详细地址':'详细地址',
    '发货':'发货',
    '发货成功':'发货成功',
    '取消订单':'取消订单',
    '核销订单':'核销订单',
    '发货备注':'发货备注',

    '订单原价':'订单原价',
    '实付金额':'实付金额',
    '运费':'运费',
    '是否首单':'是否首单',
    '是否催发货':'是否催发货',

    '预约日期':'预约日期',
    '预约开始时间':'开始时间',
    '预约结束时间':'结束时间',
    '是否需要预约':'是否需预约',
    '售卖结束时间':'售卖结束时间',
    '核销码':'核销码',
    '套餐信息':'套餐信息',
    '人员信息':'人员信息',

  },
  '售后订单': {
    '原订单编号': '原订单编号',
    '售后单编号': '售后单编号',
    '售后类型': '售后类型',
    '售后状态': '售后状态',
    '售后凭证': '售后凭证',
    '购买数量': '购买数量',
    '售后数量': '售后数量',
    '申请开始时间':'申请开始时间',
    '申请结束时间':'申请结束时间',
    '退款金额': '退款金额',
    '申请时间': '申请时间',
    '售后原因': '售后原因',
    '售后信息': '售后信息',
    '原订单信息': '原订单信息',
    '订单详情':'订单详情',
    '售后详情':'售后详情',
    '售后商品':'售后商品',
    '备注':'备注',
    '审核':'审核',
    '是否同意':'是否同意',
    '拒绝原因':'拒绝原因',
    '审核成功':'审核成功',
    '退款': '退款',
    '是否确认退款': '是否确认退款',
    '退款成功': '退款成功',
    '收货地址':'收货地址',


  },

  '用户': {
    '发送消息':'发送消息',
    '手机号':'手机号',
    '联系方式':'联系方式',
    '用户等级':'等级',
    '邮箱':'邮箱',
    '昵称':'昵称',
    '微信':'微信',
    '积分':'积分',

    '真实姓名':'真实姓名',
    '身份证':'身份证',
    '头像':'头像',
    '性别':'性别',
    '生日':'生日',
    '邀请码':'邀请码',
    '注册时间':'注册时间',
    '用户状态':'用户状态',
  },
  '用户积分变动记录':{
    '订单编号':'订单编号',
    '备注':'备注',
    '变动的积分数':'变动的积分数',
    '变动前剩余积分':'变动前剩余积分',
    '变动后剩余积分':'变动后剩余积分',
  },


  '用户搜索记录': {
    '类型':'类型',
    '搜索内容':'搜索内容',
    '搜索次数':'搜索次数',
    '创建时间':'创建时间',
  },

  '用户注销': {
    '注销原因':'注销原因',
    '审核状态':'审核状态',
    '审核失败原因':'审核失败原因',
    '审核人':'审核人',
    '审核时间':'审核时间',
    '注销时间':'注销时间',
  },

  '意见反馈': {
    '用户':'用户',
    '处理状态':'处理状态',
    '备注':'备注',
    '处理':'处理',
    '反馈图片':'反馈图片',
    '联系方式':'联系方式',
    '反馈类型':'反馈类型',
    '反馈内容':'反馈内容',
    '处理结果':'处理结果',
    '创建时间':'创建时间',
  },

  '物流公司信息': {
    '编号': '编号',
    '公司名称': '公司名称',
    '公司编码': '公司编码',
    '公司类型': '公司类型',
    '公司logo': '公司logo',
    '是否选用': '是否选用',
    '排序': '排序',
    '备注': '备注',

  },
  '报表': {
    '商品': {
      '订单开始时间': '订单开始时间',
      '订单结束时间': '订单结束时间',
      '销售占比': '销售占比',
      '退款占比': '退款占比',
      '点击量': '点击量',
      '收藏量': '收藏量',
      '订单量': '订单量',
      '商品销量': '商品销量',
      '加购量': '加购量',
      '商品销售额': '商品销售额',
      '订单销售金额': '订单销售金额',
      '销售金额': '销售金额',
      '退款金额': '退款金额',
      '退款单数': '退款单数',
      '退款量': '退款量',
    },
    '用户': {
      '注册开始时间': '注册开始时间',
      '注册结束时间': '注册结束时间',
      '支付金额': '支付金额',
      '最后下单时间': '最后下单时间',
      '购买商品量': '购买商品量',
    },
  },


  '商品对账单': {
    '账单日期': '账单日期',
    '账单月份': '账单月份',

  },
  '交易单':{
    '交易单号':'交易单号',
    '订单编号':'订单支付号',
    '订单类型':'订单类型',
    'ip':'ip',
    '支付方式':'支付方式',
    '交易类型':'交易类型',
    '支付金额':'支付金额',
    '账期金额':'账期金额',
    '手续费':'手续费',
    '支付信息':'支付信息',
    '三方订单号':'三方订单号',
    '三方支付流水号':'三方支付流水号',
    '支付状态': '支付状态',
    '交易完成时间':'交易完成时间',
    '创建时间':'创建时间',
    '过期时间':'过期时间',
    '备注':'备注',
  },
  '常见问题':{
    '常见问题标题':'常见问题标题',
    '常见问题简述':'常见问题简述',
    '问题分类':'问题分类',
    '常见问题内容':'常见问题内容',
    '显示顺序':'显示顺序',
    '状态':'状态',
    '备注':'备注',
    '创建人':'创建人',
    '插入时间':'插入时间',
    '修改人':'修改人',
    '修改时间':'修改时间',
    '版本号':'版本号',
  },
  '常见问题分类':{
    '主图':'主图',
    '标题':'标题',
    '显示顺序':'显示顺序',
    '备注':'备注',
    '创建人':'创建人',
    '插入时间':'插入时间',
    '修改人':'修改人',
    '修改时间':'修改时间',
    '版本号':'版本号',
  },
  '店铺分类': {
    '店铺分类': '店铺分类',
    '分类名称': '分类名称',
    '分类图片':'分类图片',
    '上级分类': '上级分类',
    '分类等级': '分类等级',
    '是否显示': '是否显示',
    '是否首页展示': '是否首页展示',
    '是否参与筛选': '是否参与筛选',
    '创建时间': '创建时间',
  },
  '入驻时长': {
    '入驻时长（年）': '入驻时长（年）',
    '分类名称': '分类名称',
    '分类图片':'分类图片',
    '上级分类': '上级分类',
    '分类等级': '分类等级',
    '是否显示': '是否显示',
    '是否首页展示': '是否首页展示',
    '是否参与筛选': '是否参与筛选',
    '创建时间': '创建时间',
  },
  '主营行业': {
    '分类名称': '行业名称',
    '创建时间': '创建时间',
  },
  '店铺': {
    '店铺名称': '店铺名称',
    '店铺logo': 'logo',
    '店铺背景图':'背景图',
    '客服电话': '客服电话',
    '入驻时长': '入驻时长(年)',
    '入驻类型': '入驻类型',
    '是否推荐': '是否推荐',
    '账户余额': '账户余额',
    '已结算金额': '已结算金额',
    '店铺状态': '店铺状态',
    '关键词': '关键词',
    '营业时间': '营业时间',
    '店铺分类': '店铺分类',
    '基础信息': '基础信息',
    '主营行业': '主营行业',
    '地址信息': '地址信息',
    '店铺所在省份': '省份',
    '店铺所在城市': '城市',
    '店铺所在县': '县',
    '经度': '经度',
    '纬度': '纬度',
    '详细地区': '详细地址',
    '店铺简介': '店铺简介',
    '公司申请类型': '申请类型',
    '店铺时间': '创建时间',
    '入驻资料': '入驻资料',
    '法人姓名': '法人姓名',
    '法人手机号': '法人手机号',
    '法人邮箱': '法人邮箱',
    '法人身份证号': '法人身份证号',
    '身份证正面': '身份证正面',
    '身份证反面': '身份证反面',
    '营业执照': '营业执照',
    '真实姓名': '真实姓名',
    '结算账户': '结算账户',
    '银行预留手机号': '银行预留手机号',
    '结算银行开户名': '银行开户名',
    '结算开户银行支行名称': '开户银行支行名称',
    '结算开户银行所在地': '开户银行所在地',
    '审核信息':'审核信息',
    '保证金信息': '保证金信息',
    '保证金': '保证金',
    '打款备注': '打款备注',
    '打款截图': '打款截图',
    '店铺状态是否开启': '店铺状态是否开启',
  },
  '账户类型': {
    '账户类型名称': '账户类型名称',
    '创建时间': '创建时间',
  },
  '店铺标签': {
    '标签名称': '标签名称',
    '创建时间': '创建时间',
  },
  '线下店铺套餐内容': {
    '备注': '套餐标题',
    '有效期': '有效期说明',
    '使用时间': '使用时间说明',
    '使用规则': '使用规则说明',
    '套餐logo': '内容图片',
    '创建时间': '创建时间',
    '是否需要预约': '是否需要预约',
    '是否需要添加人员': '是否需要添加人员',
  },
  '线下店铺套餐详情': {
    '商品套餐': '商品套餐',
    '套餐主标题': '套餐主标题',
    '套餐副标题': '套餐副标题',
    '套餐详情价格': '套餐价格',
    '排序': '排序',
    '创建时间': '创建时间',
    '套餐名称': '套餐名称',
    '手机号': '手机号',
    '姓名': '姓名',
  },

  '优惠券类型':{
    '店铺ID':'店铺ID',
    '优惠券名称':'优惠券名称',
    '发放面额':'发放面额',
    '发放数量':'发放数量',
    '每人最大领取个数 0无限制':'每人最大领取个数 0无限制',
    '满多少元使用':'满多少元使用',
    '领取人会员等级':'领取人会员等级',
    '使用范围':'使用范围',
    '业务ID，与type合并使用，如type为1，全场可用，为2，分类id，以此类推':'业务ID，与type合并使用，如type为1，全场可用，为2，分类id，以此类推',
    '有效日期开始时间':'有效开始时间',
    '有效日期结束时间':'有效结束时间',
    '优惠券状态':'状态',
    '是否允许首页显示0不显示1显示':'是否允许首页显示0不显示1显示',
    '优惠券详细信息':'优惠券详细信息',
    '是否新人优惠券':'是否新人优惠券',
    '创建时间':'创建时间',
    '修改时间':'修改时间',
    '创建人':'创建人',
    '修改人':'修改人',
    '逻辑删除标记 1:删除,0:未删除':'逻辑删除标记 1:删除,0:未删除',
    '备注':'备注',
    '店铺':'店铺',
  },
  '秒杀时间轴':{
    '主键id':'主键id',
    '秒杀开始时间':'秒杀开始时间',
    '秒杀结束时间':'秒杀结束时间',
    '是否显示 0：否 1：是':'是否显示 0：否 1：是',
    '活动名称':'活动名称',
    '创建时间':'创建时间',
    '修改时间':'修改时间',
    '创建人':'创建人',
    '修改人':'修改人',
    '逻辑删除标记 1:删除,0:未删除':'逻辑删除标记 1:删除,0:未删除',
    '备注':'备注',
  },
  '秒杀库存':{
    '主键id':'主键id',
    '时间轴id':'时间轴',
    '商品id':'商品id',
    '商品名称':'商品名称',
    '秒杀价格':'秒杀价格',
    '商品现价':'商品现价',
    '已经秒杀数量':'已经秒杀数量',
    '商品图片':'商品图片',
    '秒杀商品库存':'秒杀商品库存',
    '店铺id':'店铺id',
    '店铺名字':'店铺名字',
    '状态':'状态',
    '秒杀是否完成0未完成 1已抢完':'秒杀是否完成0未完成 1已抢完',
    '拒绝原因':'审核意见',
    '秒杀开始时间':'秒杀开始时间',
    '秒杀结束时间':'秒杀结束时间',
    '最大购买数量':'最大购买数量',
    '创建时间':'创建时间',
    '修改时间':'修改时间',
    '创建人':'创建人',
    '修改人':'修改人',
    '逻辑删除标记 1:删除,0:未删除':'逻辑删除标记 1:删除,0:未删除',
    '备注':'备注',
  },
  '商家退货地址':{
    '商家退货地址id':'商家退货地址id',
    '店铺id':'店铺id',
    '收货人名字':'收货人名字',
    '收货人手机号码':'收货人手机号码',
    '收货人座机':'收货人座机',
    '邮政编码':'邮政编码',
    '省id':'省id',
    '省':'省',
    '城市id':'城市id',
    '城市':'城市',
    '区id':'区id',
    '区':'区',
    '具体地址':'具体地址',
    '默认地址':'是否默认',
    '状态':'是否可用',
    '更新时间':'更新时间',
    '创建时间':'创建时间',
  },
  '用户标签':{
    '标签id':'标签id',
    '标签名称':'标签名称',
    '创建时间':'创建时间',
    '修改时间':'修改时间',
    '创建人':'创建人',
    '修改人':'修改人',
    '备注':'备注',
  }
}
