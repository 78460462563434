import antdKoKR from 'ant-design-vue/es/locale-provider/ko_KR'
import momentKO from 'moment/locale/ko'
import setting from './ko-KR/setting'

const components = {
  antLocale: antdKoKR,
  momentName: 'ko-kr',
  momentLocale: momentKO
}

export default {
  'message': '-',

  ...components,
  ...setting
}
